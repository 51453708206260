import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Home({ data: { strapi: { givingPage } }, location }) {

	return (
		<>
			<SEO title="Giving" description={ givingPage.seoDescription } pathname={ location.pathname } />

			{ givingPage && givingPage.content &&
				<DynamicZone components={ givingPage.content } />
			}
		</>
	)
}

export const query = graphql`
	query givingPageQuery {
		strapi {
			givingPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentCommonCallToActionBanner {
						id
						title
						description
						link
						linkLabel
						isExternalLink
						link2 
						linkLabel2
						isExternalLink2
						link3 
						linkLabel3 
						isExternalLink3
					}
					... on StrapiQuery_ComponentGivingImageCardList {
						id
						imageCard {
							id
							imageCardLink
							imageCardLinkText
							imageCardDescription
							imageCardTitle
							imageCardImage {
								id
								url
								imageFile {
									childImageSharp {
										fluid {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentGivingHorizontalImageCard {
						id
						horizontalImageCardDescription
						horizontalImageCardTitle
						horizontalImageCardImage {
							id
							url
							imageFile {
								childImageSharp {
									fluid {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentCommonLinkStatement {
						id
						linkStatementLinkText
						linkStatementTitle
						linkStatementUrl
					}
					... on StrapiQuery_ComponentCommonStatement {
						statementTitle
						statementSubTitle
					}
					... on StrapiQuery_ComponentCommonVideo {
						id
						videoNumber
						videoTitle
					}
				}
			}
		}
	}
`